import React from 'react';
import DohaBG from './DohaBG';



const DynamicBackground = ({ isPortrait, animateImages, number}) => {
    return (
    <>
    {number === 1?
    <DohaBG isPortrait={isPortrait} animateImages={animateImages} />
    :
    <></>

}
    
    
    </> );
};

export default DynamicBackground;