import '../Styles.css'
import CelebrationScreen from "../reusables/CelebrationScreen";
import TimeUpPopup from '../reusables/TimeUpPopup';
import DynamicBG from '../reusables/backgrounds/DynamicBackground';
import GameIntroPage from '../reusables/GameIntroPage';
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import soundIcon from '../../Assets/sound.png'
import menu from '../../Assets/menu.png'
import timer from '../../Assets/timer.png'
import max from '../../Assets/maximize.png'
import min from '../../Assets/minimize.png'

import settingsIcon from '../../Assets/settings.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


//import { useMediaQuery } from '@mui/material';
import { useRoute } from 'wouter';


function ClickPick() {
  const [match, params] = useRoute('/play/ClickPick/:id'); // Use the same route pattern
  const { id } = params; // Get the id from params
  const [questions, setQuestions] = useState([{
    question: "zft", answers: [{ text: "Mercury", isCorrect: true },
    { text: "Earth", isCorrect: true },]
  }]);
  const [settings, setSettings] = useState([{}]);
  const [isOneAnswer, setIsOneAnswer] = useState(false);



  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const [sound, setSound] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [time, setTime] = useState(25); // Initial time
  const [isTimerActive, setTimerActive] = useState(false); // Timer is active
  const [animationKey, setAnimationKey] = useState(0);
  const currentQuestion = questions[currentQuestionIndex];
  const [isIphone, setIPhone] = useState(false)
  const [count, setCount] = useState(3);
  const [start, setstart] = useState(false);
  const [finish, setFinish] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [animateImages, setAnimateImages] = useState(false); // Control the animation of images
  const [timeUp, setTimeUp] = useState(false);
  const videoRef = useRef(null);

  const containerRef = useRef(null);

  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);
  const [isConditionMet, setIsConditionMet] = useState(false);

  const [countdown, setCountdown] = useState(5);
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    let timer;
    if (showIndicator) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);

            setShowIndicator(false); // Hide the indicator after the countdown
            handleSubmit(); // Trigger the next question
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [showIndicator]);
  const areAllQuestionsValid = () => {
    return questions.every((question) => {
      // Count how many answers have isCorrect: true
      const correctAnswers = question.answers.filter((answer) => answer.isCorrect).length;
      // Ensure only 1 correct answer exists
      return correctAnswers === 1;
    });
  };

  useEffect(() => {
    setIsOneAnswer(areAllQuestionsValid());
  }, [questions]);

  useEffect(() => {
    GetQuestions();
    GetGame();

    const handleResize = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;


      if (height - width <= 300 && height > 700) {
        setIsConditionMet(true);
      } else {
        setIsConditionMet(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  ////////////////////////// start /////////////////////////////
  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    const handleOrientationChange = (e) => {
      if (isMobile) {
        setIsPortrait(e.matches);
      }
      else {
        setIsPortrait("none");
      }

      // Update state based on orientation
    };

    mediaQuery.addEventListener('change', handleOrientationChange);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);
  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detect iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    // Detect Samsung (and other Android devices)
    if (/android/i.test(userAgent)) {
      if (/Samsung/i.test(userAgent)) {
        return "Samsung";
      }
      return "Android";
    }

    return "Other";
  };
  useEffect(() => {
    const deviceType = getDeviceType();

    if (deviceType === "iOS") {

      setIPhone(true);
      // } else if (deviceType === "Samsung" || deviceType === "Android") {
      //   setMobile(true);
    }

    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreen(false); // Call handleFullScreen when exiting fullscreen
      }
    };

    document.addEventListener('fullscreenchange', onFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };

  }, []);

  const handleStart = () => {
    // Play video when the button is clicked
    setstart(true);
    setCount(3)
    setFinish(false);
    setScore(0);


    if (videoRef.current) {

      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setTimeout(() => {


        //playSound(1);
      }, 1000);
    }



    //playSound(0);
    setVideoVisible(true);

    setTimeout(() => {

      setAnimateImages(true); // Start animating side images
      setVideoVisible(false);

    }, 3000);

    // Show the wheel after 4 seconds
    setTimeout(() => {
      setShowGame(true);
      setTime(25);
      setAnimationKey((prev) => prev + 1);
      setTimerActive(true);
    }, 5000);
    setAnimateImages(false);
    // Start the timer
  };


  ////////////////////////// timer /////////////////////////////
  useEffect(() => {
    let interval = null;

    if (isTimerActive && time > 0) {
      interval = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000); // Update every second
    } else {
      clearInterval(interval);
      if (time === 0) {
        setTimeUp(true); // Call the callback function when time is up
        setCountdown(5);
        setShowIndicator(true);
      }
    }

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [isTimerActive, time]);
  ////////////////////////////end of timer ///////////////////////


  const handleAnswerClick = (index) => {
    if (!submitted) {
      setSelectedAnswers((prev) =>
        prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
      );
    }
  };

  const handleSubmit = () => {
    if (submitted || timeUp) {
      setTimeUp(false);
      setTime(25);
      setAnimationKey((prev) => prev + 1);
      setTimerActive(true);
      setSelectedAnswers([]);
      setSubmitted(false);
      setShowIndicator(false);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prev) => prev + 1);
      } else {
        setTimeUp(false);
        setTimerActive(false);
        setShowGame(false);
        setFinish(true);
        setCurrentQuestionIndex(0);
        //setScore(0);
      }
    } else {
      // Check answers and update score
      setCountdown(5);
      setShowIndicator(true); // Start the countdown

      setTimerActive(false);
      const correctAnswers = currentQuestion.answers
        .map((answer, index) => (answer.isCorrect ? index : null))
        .filter((index) => index !== null);

      const pointsEarned = selectedAnswers.reduce((acc, index) => {

        if (correctAnswers.includes(index)) {
          return acc + 10; // Add 10 points for a correct answer
        } else {
          return acc - 5; // Subtract 5 points for an incorrect answer
        }
      }, 0);
      const timeC = time / 10 + 1;
      setScore((prev) => prev + (pointsEarned * timeC));
      setSubmitted(true);
    };
  }

  const handleSound = () => { if (sound) { setSound(false); } else { setSound(true); } };


  const handleFullScreen = () => {
    if (!isFullscreen) {
      // Enter fullscreen
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      }
      else if (containerRef.current.webkitEnterFullscreen) {
        containerRef.current.webkitEnterFullscreen();
      }
      else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      //Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  ////////////////////////////////  api //////////////////////////////////
  const GetQuestions = async () => {
    try {
      const response = await fetch(`https://qnar.fun/api/questions/?game=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log('Questions', data); // Use the data in your front-end logic
      setQuestions(data)
      return data;
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };
  const GetGame = async () => {
    try {
      const response = await fetch(`https://qnar.fun/api/games/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setSettings(data)
      return data; // Return the game ID for creating questions
    } catch (error) {
      console.error('Error posting game:', error);
    }
  };

  return (
    <>
      <Box ref={containerRef} className={isPortrait ? 'FSMB' : isFullscreen ? 'FSbg' : 'normalDT'}
        sx={{ flexDirection: 'column', flexWrap: 'wrap', borderRadius: 2, boxShadow: 3, textAlign: 'center', overflow: 'hidden' }}>
        {/* Background Image */}
        <DynamicBG isPortrait={isPortrait} animateImages={animateImages} number={1} />

        {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////  */}
        <GameIntroPage
          isPortrait={isPortrait}
          start={start}
          showGame={showGame}
          settings={settings}
          isFullscreen={isFullscreen}
          handleStart={handleStart}
          handleFullScreen={handleFullScreen}
          VideoVisible={videoVisible}
          isIphone={isIphone}
          videoRef={videoRef}
        />
        {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////  */}


        <Box className={`game-container ${showGame ? (isFullscreen ? 'show FSMT' : 'show FSMT') : ''}`} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, height: '6vh' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{
              position: 'relative',
              width: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Set desired size
              height: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Square aspect ratio
              overflow: 'hidden',
            }} >
              <img src={soundIcon} alt="button" onClick={handleSound} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }} />
            </Box>
            <Box sx={{
              position: 'relative',
              width: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Set desired size
              height: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Square aspect ratio
              overflow: 'hidden',
            }} >
              <img src={menu} alt="button" onClick={handleSound} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }} />
            </Box>
            <Box sx={{
              position: 'relative',
              width: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Set desired size
              height: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Square aspect ratio
              overflow: 'hidden', // Prevent the image from spilling out
            }}>
              <img
                src={timer}
                alt="button"
                onClick={handleSound}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain', // This ensures the whole image is visible within the container
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute',
                  color: '#3B5D44',
                  fontWeight: 'bold',
                  fontSize: { xs: '1.2rem', sm: '1.2rem', md: '1.6rem', lg: '2rem' }, // Font size adjusts across breakpoints
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)', // Center the text
                }}
              >
                {time}
              </Typography>
            </Box>
          </Box>

          <Box className={"green-to-white-wrapper"} sx={{ marginright: '20px', display: { xs: 'none', md: "block" }, height: { xs: '10vw', ssm: '7vw', md: '5vw', lg: '4vw' } }} key={animationKey}>
            <div className={`green-to-white-overlay ${isTimerActive ? '' : 'paused'}`} style={{ display: isPortrait ? 'none' : "block", }}></div>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Box sx={{
              background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))`, color: 'FFFFFF', borderRadius: '10px',
              borderColor: '#4B7857', borderWidth: '3px', borderStyle: 'solid', position: 'relative',
              width: { xs: '24vw', sm: '18vw', md: '14vw', lg: '10vw' }, // Set desired size
              height: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Square aspect ratio
              overflow: 'hidden', // Prevent the image from spilling out

              padding: isFullscreen ? '10px' : { xs: '3px', sm: '3px', md: '5px' },
              fontSize: { xs: '1rem', sm: '1.5rem', md: '2rem' },
              color: '#3B5D44'
            }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Score: <span style={{ color: '#EB8576' }}>{score}</span></Typography>
            </Box>
            <Box sx={{
              position: 'relative',
              width: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Set desired size
              height: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, // Square aspect ratio
              overflow: 'hidden', // Prevent the image from spilling out
            }}>
              <img src={isFullscreen ? min : max} alt="button" onClick={handleFullScreen} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain', // This ensures the whole image is visible within the container
              }} />
            </Box>
          </Box>
        </Box>
        {isConditionMet ? <Box sx={{ height: '5vh', width: '100%' }}></Box> : ''} {/* Spacer Box */}
        <Box className={`game-container ${showGame ? (isFullscreen ? 'show FSMT' : 'show FSMT') : ''}`} sx={{ display: { xs: 'flex', md: "none" }, height: { xs: '10vw', sm: '7vw', md: '5vw', lg: '4vw' }, justifyContent: 'center', width: '80%', margin: '0 auto', }}>

          <div className={"green-to-white-wrapper"} style={{ marginright: '20px', }} key={animationKey}>
            <div className={`green-to-white-overlay ${isTimerActive ? '' : 'paused'}`}></div>
          </div>

        </Box>
        {/* /////////////////////////////////// end of navbar/////////////////////////////////////////////////////// */}

        
        <Box className={`game-container ${showGame ? isMobile ? 'show FSMTM' : (isFullscreen ? 'show FSMT' : 'show') : ''}`}
          sx={{
            display: 'flex', justifyContent: 'center', mb: 2, background: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))`, color: 'FFFFFF', borderRadius: '10px',
            borderColor: '#4B7857', borderWidth: '3px', borderStyle: 'solid',
            padding: { xs: '5px', sm: '10px' },
            fontSize: {
              xs: '1rem', sm: '1.5rem', md: '2rem',
              fontWeight: "bold",
              color: '#3B5D44'
            }
          }}>{currentQuestion.title}</Box>
        <Box className={`game-container ${showGame ? isMobile ? 'show FSMTM' : (isFullscreen ? 'show FSMT' : 'show') : ''}`}
          sx={{

            display: 'flex',
            flexWrap: 'wrap', // Wrap items to new rows if needed
            justifyContent: 'center', // Center items within the Box
            alignItems: 'center', // Align items vertically within the Box
            gap: 4,
            maxWidth: { xs: '100%', sm: '100%', md: '60%' }, // Limits container width for centering
            mx: 'auto', // Centers the Box horizontally
            height: '53vh', // Restrict container to viewport height
            overflow: 'auto', // Allows internal scrolling if items overflow
            padding: '20px', // Adds padding for better spacing
            boxSizing: 'border-box', // Ensures padding is included in the height calculation


          }}
        >
          {currentQuestion.answers.map((answer, index) => {
            // Determine background color based on answer state
            let icon = null;

            let backgroundColor = '#E6F8EB'; // Default color

            if (submitted) {
              if (answer.isCorrect && !selectedAnswers.includes(index)) {
                // Missed correct answer
                backgroundColor = 'lightgreen';
                icon = <WarningIcon sx={{ color: 'white', marginBottom: '-20px' }} />; // Icon for missed correct answer
              } else if (answer.isCorrect) {
                // Correct answer selected
                backgroundColor = 'green';
                icon = <CheckIcon sx={{ color: 'white', marginBottom: '-20px' }} />;
              } else if (selectedAnswers.includes(index)) {
                // Incorrect answer selected
                backgroundColor = 'red';
                icon = <CloseIcon sx={{ color: 'white', marginBottom: '-20px' }} />;
              }
            } else if (selectedAnswers.includes(index)) {
              // Selected before submission
              backgroundColor = '#FFFFCD';
            }

            return (
              <Button
                key={index}
                onClick={() => { handleAnswerClick(index); if (isOneAnswer) { handleSubmit(); } }}
                sx={{
                  bgcolor: backgroundColor,
                  color: 'black',
                  marginTop: '10px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  //fontSize: { xs: '1rem', sm: '1rem', md: '1.5rem' },
                  gap: 3,
                  width: { xs: '100px', sm: '100px', md: '100px', lg: '150px' },
                  height: { xs: '100px', sm: '100px', md: '100px', lg: '150px' },
                  overflow: 'hidden', // Ensure no content overflows the button
                  textOverflow: 'ellipsis', // Handle long text gracefully
                  whiteSpace: 'normal', // Allow text to wrap
                  wordWrap: 'break-word', // Break long words if necessary
                  fontSize: 'clamp(0.8rem, 1vw, 1rem)', // Responsive font size to fit text within bounds
                  lineHeight: '1.2', // Adjust line height for better readability
                  borderRadius: '10px',
                  '&:hover':
                    isMobile ? {
                      '&:hover': {
                        bgcolor: backgroundColor, // Keep the background color the same
                        color: 'black',
                        boxShadow: 'none', // Prevent any shadow changes
                      },
                    } : {
                      bgcolor: 'lightblue',
                      transform: 'scale(1.05)'
                    },
                }}
              >
                {icon}
                <Box lang="en"
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 'inherit', // Use the font size defined in the button
                    overflowWrap: 'break-word', // Allow breaking of long words
                    wordBreak: 'break-word', // Ensure word breaks properly
                    hyphens: 'auto',
                    WebkitHyphens: 'auto', // Add hyphen support for Safari
                    MozHyphens: 'auto', // Add hyphen support for Firefox
                    lang: 'en', // Add hyphens when words are broken
                  }}
                >
                  {answer.text}
                </Box>

              </Button>
            );
          })}

        </Box>
        <Grid
          container
          justifyContent="center"  // Centers the items horizontally within the grid
          alignItems="center"      // Vertically center the items
          style={{
            width: "100%",          // Ensures the grid takes full width
          }}
        >
          {/* Button */}
          <Grid item>
            <Button
              className={`game-container ${showGame
                ? isMobile
                  ? "show FSMTM"
                  : isFullscreen
                    ? "show FSMT"
                    : "show"
                : ""
                }`}
              variant="contained"
              onClick={handleSubmit}
              sx={{
                position: isPortrait ? "fixed" : "",
                bottom: "0px",
                right: "0px",
                mt: 3,
                fontWeight: "bold",
                width: "fit-content", // Ensures the button is appropriately sized
                backgroundColor: "#EB8576",
                marginBottom: "20px",
                display: isOneAnswer && !submitted ? "none" : "block", // Conditional button visibility
              }}
            >
              {submitted
                ? currentQuestionIndex < questions.length - 1
                  ? "Next Question"
                  : "Finish"
                : "Submit"}
            </Button>
          </Grid>

          {/* Countdown */}
          {showIndicator && !timeUp && (
            <Grid item>
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  fontSize: "2rem",
                }}
              >
                {countdown}
              </span>
            </Grid>
          )}
        </Grid>
        {finish && (
          <CelebrationScreen
            score={score}
            onReplay={handleStart}
          />
        )}
      </Box>

      {/* Time's Up Popup */}
      <TimeUpPopup
        timeUp={timeUp}
        countdown={countdown}
        handleSubmit={handleSubmit}
        currentQuestionIndex={currentQuestionIndex}
        questions={questions}
      />


    </>

  );

}




export default ClickPick;
