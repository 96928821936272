import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, TextField, Button, Grid, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import wheel from '../Assets/spinIcon.png'
import ClickPick from '../Assets/ClickPick.png'
import { useTranslation } from 'react-i18next';
import { useLocation,useRoute } from 'wouter';


function GameChoose({game}) {
  const [match, params] = useRoute('/GameSelect/:id'); // Use the same route pattern
  const { id } = params; // Get the id from params
    const {t, i18n} = useTranslation();
    const [location, setLocation] = useLocation();
    const [games, setGames] = useState([{}]);

    useEffect(() => {
        // Fetch all games when the component mounts
        GetGames();
    }, []);

    const GetGames = async () => {
        try {
          const response = await fetch(`https://qnar.fun/api/games/type/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          setGames(data);
          return data; // Return the game ID for creating questions
        } catch (error) {
          console.error('Error posting game:', error);
        }
    };
  return (
    <div style={{  backgroundColor: '#F9F9ED' ,color :'#4B7857' }}>
    {/* Game Icon and Description */}
    <Box sx={{ display: 'flex', padding: '20px', flexDirection: 'column', justifyContent: 'center', alignItems:'center' }}>
        {/* <img src={id == 2 ? ClickPick : wheel} alt="Game Icon" style={{ width: '20vw', height: 'auto', marginRight:'20px' , marginLeft:'20px'}} /> */}
        <Box sx={{ display: 'flex', padding: '20px', flexDirection: 'column', justifyContent: 'center', alignItems:'center' }}>
        <Typography  variant="h3" style={{ fontWeight:'bold'}}gutterBottom>
          {id == 2? t('ClickPickTitle'): t('SpinningH1')}
          </Typography>
        <Typography variant="h6">
        {id == 2? t('ClickPickDesc'): t('SpinningH2')}
          </Typography>
        </Box>
    </Box>
    <Paper style={{ padding: '20px', marginBottom: '20px', marginLeft: 20, marginRight:20 ,color :'#4B7857', fontWeight:'bold'}}>
       {/* Rendering 4 random papers inside each main paper */}
       <Grid container spacing={2}>
            {games.map((item, idx) => (
                <Grid item key={idx} xs={12} sm={3}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: '100%',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={() => setLocation(`/play/clickpick/${item.id}`)}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                height: '235px',
                                backgroundImage: id == 2? `url(${ClickPick})`: `url(${wheel})`,
                                backgroundSize: 'contain', // Ensure image covers the entire box
                                backgroundPosition: 'center', // Center the image within the box
                                backgroundRepeat: 'no-repeat',
                                marginBottom: '10px',
                                
                            }}
                        />
                        <Typography variant="h4" style={{color:'#4B7857', fontWeight: 'bold'}}>{item.title}</Typography>
                        <Typography variant="body1" style={{ fontWeight: 'bold'}} color="textSecondary">
                            Created by {item.creator_username}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>

    </Paper></div>
  )
}

export default GameChoose