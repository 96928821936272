import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const TimeUpPopup = ({
  timeUp,
  countdown,
  handleSubmit,
  currentQuestionIndex,
  questions,
}) => {
  if (!timeUp) return null; // If timeUp is false, do not render the popup

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent overlay
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2000,
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 2,
          padding: 4,
          textAlign: 'center',
          width: '90%',
          maxWidth: '400px',
          boxShadow: 24,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#d32f2f', // Red color for alert
            mb: 2,
          }}
        >
          Time is up!
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            fontWeight: 'bold',
            fontSize: '1.2rem',
            padding: '10px 20px',
          }}
        >
          {currentQuestionIndex < questions.length - 1 ? "Next Question" : "Finish"}
        </Button>
        <span
          style={{
            color: 'red',
            fontWeight: 'bold',
            marginLeft: '10px',
            marginBottom: '-10px',
            fontSize: '2rem',
          }}
        >
          {countdown}
        </span>
      </Box>
    </Box>
  );
};

export default TimeUpPopup;
