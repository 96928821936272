// GameIntroPage.js
import React, { useState, useEffect, useRef } from 'react';import { Box, Button, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import backTop from '../../Assets/Upper.png'
import backTopMobile from '../../Assets/topmobile.png'
import backBottom from '../../Assets/lowerbg.png'
import backBottomMobile from '../../Assets/bottommobile.png'
import settingsIcon from '../../Assets/settings.png'
import counting3 from '../../Assets/output.webm'
import max from '../../Assets/maximize.png'
import min from '../../Assets/minimize.png'

const GameIntroPage = ({ isPortrait, start, showGame, settings, isFullscreen, VideoVisible, handleStart, handleFullScreen, isIphone, videoRef }) => {
    const [progressPercent, setProgressPercent] = useState(0);
    const [count, setCount] = useState(3);
    const radius = 100; // radius of the circle
    const circumference = 2 * Math.PI * radius;


    useEffect(() => {
        if (!start || !isIphone) return;
        const progressCircle = document.querySelector(".progress");
    
        // Set initial styles for the progress circle (when count is 3)
        progressCircle.style.strokeDasharray = `${circumference} ${circumference}`;
        progressCircle.style.transition = 'stroke-dashoffset 0.5s ease-in-out'; // Smooth transition
      
        const progressInterval = setInterval(() => {
          if (count === 3) {
            progressCircle.style.strokeDashoffset = circumference - (33.3 / 100) * circumference;
            // navigate('/other'); // Uncomment to navigate after countdown ends (if desired)
          } else if (count === 2) { 
            progressCircle.style.strokeDashoffset = circumference - (66.6 / 100) * circumference;
          }
         else if (count === 1) { 
          progressCircle.style.strokeDashoffset = circumference - (100 / 100) * circumference;
        }
       else if (count === 0) { 
        progressCircle.style.strokeDashoffset = circumference - (100 / 100) * circumference;
      }
      
          setCount(prevCount => prevCount - 1); // Decrement count
        }, 1000);
      
        return () => {
          clearInterval(progressInterval);
        };
      }, [count, circumference,start, isIphone]);
  
  
    return (
    <>
      <Box
        sx={{
          p: 3,
          borderRadius: 2,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: isPortrait ? `url(${backTopMobile})` : `url(${backTop})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: start ? 0 : 1,
          transform: start ? 'translateY(-100%)' : 'translateY(0)',
          transition: 'opacity 1s ease, transform 1s ease',
          zIndex: 99,
          display: showGame ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom color={'white'} sx={{ position: 'absolute', top: 70, whiteSpace: 'pre-line' }}>
          {settings.title}
        </Typography>
      </Box>

      <Box
        sx={{
          p: 3,
          borderRadius: 2,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: isPortrait ? `url(${backBottomMobile})` : `url(${backBottom})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          opacity: start ? 0 : 1,
          transition: 'opacity 1s ease, transform 1s ease',
          zIndex: 99,
          transform: start ? 'translateY(100%)' : 'translateY(0)',
          display: showGame ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: isPortrait ? '10%' : '5%',
            width: '90%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
            gap: '10px',
          }}
        >
          <img
            src={settingsIcon}
            alt="Settings"
            style={{
              maxWidth: '60px',
              maxHeight: '60px',
              width: 'clamp(40px, 8vw, 60px)',
              height: 'clamp(40px, 8vw, 60px)',
              cursor: 'pointer',
            }}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#fff',
              color: '#4B7857',
              fontSize: 'clamp(1rem, 2vw, 1.5rem)',
              padding: 'clamp(8px, 2vw, 16px) clamp(16px, 4vw, 32px)',
              '&:hover': { backgroundColor: 'grey' },
            }}
            onClick={handleStart}>
            Start <PlayArrowIcon />
          </Button>

          <img
            src={isFullscreen ? min : max}
            alt="Maximize"
            style={{
              maxWidth: '60px',
              maxHeight: '60px',
              width: 'clamp(40px, 8vw, 60px)',
              height: 'clamp(40px, 8vw, 60px)',
              cursor: 'pointer',
            }}
            onClick={handleFullScreen}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
        }}
      >
        {isIphone ? (
          <div className={!VideoVisible ? 'fade-out' : ''} style={{ display: showGame ? 'none' : 'flex' }}>
            <div className="countdown-circle">
              <svg width="250" height="250">
                <circle r="100" cx="125" cy="125" className="track" style={{ fill: 'rgba(235, 133, 118)' }}></circle>
                <circle
                  r="100"
                  cx="125"
                  cy="125"
                  className="progress"
                  style={{
                    fill: 'none',
                    stroke: '#3B5D44',
                    strokeWidth: '10px',
                    strokeDashoffset: `calc(${circumference} - (${progressPercent} / 100) * ${circumference})`,
                  }}
                ></circle>
                {count > -1 && (
                  <text x="50%" y="50%" style={{ transform: 'none', fill: '#fff', fontSize: '60px', fontWeight: 'bold' }} dominantBaseline="middle" textAnchor="middle" className="progress-text">
                    0{count}
                  </text>
                )}
              </svg>
            </div>
          </div>
        ) : (
          <video
            id="myVideo"
            ref={videoRef}
            playsInline
            className={!VideoVisible ? 'fade-out' : ''}
            loop={false}
            src={counting3}
            alt="Timer"
            style={{ display: showGame ? 'none' : 'flex', width: '120%', height: '120%', opacity: VideoVisible ? 1 : 0 }}
          />
        )}
      </Box>
    </>
  );
};

export default GameIntroPage;
