import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const headerHeight = 64; // Adjust to your actual header height in pixels

  return (
    <div>
      <Header />
      <div style={{ marginTop: `${headerHeight}px` }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;