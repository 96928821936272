import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Link, Grid } from '@mui/material';
import axios from 'axios';
import { useLocation } from 'wouter';
import GoogleIcon from "../../Assets/logos/google.png";
import MicrosoftIcon from "../../Assets/logos/microsoft.png";
import FacebookIcon from "../../Assets/logos/facebook.png";
import AppleIcon from "../../Assets/logos/apple.png";
import pic from "../../Assets/signin.jpeg"

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [location, setLocation] = useLocation();


  const handleLogin = async () => {
    try {
      // Sending POST request to the backend with login details
      const response = await axios.post("https://qnar.fun/api/login/", {
        username_or_email: email,
        password: password,
      });
  
      // Check if the login is successful
      if (response.data.success) {
        console.log(response.data)
        // Save the first name in localStorage (or use state management as needed)
        localStorage.setItem("first_name", response.data.first_name);
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem("username", response.data.username);
        // You can also store other relevant user details if needed
        
        // Redirect the user after successful login (e.g., to the home page or dashboard)
        // This assumes you are using Wouter for routing
          // Refresh the page

        setLocation('/dashboard');
        window.location.reload(); // Replace '/dashboard' with your desired route
  
        alert('Login successful!');
      } else {
        // Handle login failure (invalid username/email or password)
        alert('Invalid username or password');
      }
    } catch (error) {
      // Handle any errors from the request
      console.error('Login failed:', error);
      alert('An error occurred during login. Please try again.');
    }
  };
  return (
    <Grid
    container
    sx={{
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
    }}
  >
    {/* Registration Form Section */}
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      sx={{
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
        height: "80vh"
      }}
    >
      <Typography variant="h4" align="center" sx={{ mb: 3, color: "#5B945B" }}>
        Welcome to Qnar
      </Typography>
      <Grid container spacing={2}>
        {/* Email Field */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        {/* Password Fields */}
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        </Grid>
        <Typography sx={{ textAlign: "center", margin: "1rem 0", color: "#5B945B" }}>
          or continue with
        </Typography>
        <Button
          variant="outlined"
          startIcon={<img src={GoogleIcon} alt="Google" style={{ width: "20px" }} />}
          sx={{ width: "100%", marginBottom: "1rem", textTransform: "none" }}
        >
          Continue with Google
        </Button>
        <Button
          variant="outlined"
          startIcon={<img src={MicrosoftIcon} alt="Microsoft" style={{ width: "20px" }} />}
          sx={{ width: "100%", marginBottom: "1rem", textTransform: "none" }}
        >
          Continue with Microsoft
        </Button>
        <Grid container justifyContent="space-around" sx={{ margin: "1rem 0" }}>
          {/* Social Media Icons */}
          {[FacebookIcon, AppleIcon].map((icon, idx) => (
            <Box
              key={idx}
              onClick={() => {
                /* Add logic here */
              }}
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <img
                src={icon}
                alt="Social Icon"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Grid>
        <Button
          variant="contained"
          onClick={handleLogin}
          sx={{
            width: "100%",
            backgroundColor: "#5B945B",
            color: "white",
            marginBottom: "1rem",
            "&:hover": { backgroundColor: "#4A7A4A" },
          }}
        >
          Log in
        </Button>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          don't have an account?{" "}
          <Link href="/signup" underline="hover">
          Sign up
          </Link>
        </Typography>
      </Grid>

      {/* Image and Arabic Quote Section */}
      <Grid item xs={12} md={6} lg={4} sx={{ textAlign: "center" , height: "80vh", display: { xs: "none", md: "block" },}}>
        <img
          src={pic}
          alt="Online Learning"
          style={{ width: "100%", height: "70vh",borderRadius: "8px", marginBottom: "1rem",  }}
        />
        <Typography variant="h5" sx={{ color: "#5B945B", fontWeight: "bold" }}>
          من سلك طريقًا يلتمسُ فيه علمًا، سهَّل اللهُ له طريقًا إلى الجنَّةِ
        </Typography>
      </Grid>

    </Grid>
  );
};
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [location, setLocation] = useLocation();

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('http://127.0.0.1:8000/api/token/', { // Updated to match Django URL
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ username, password }),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = await response.json();

//       if (data.access) {
//         localStorage.setItem('access_token', data.access);
//         localStorage.setItem('username', username);
//         setLocation('/'); // Redirect to home page
//       } else {
//         setError('Invalid username or password');
//       }
//     } catch (error) {
//       setError('There was a problem with the login request');
//     }
//   };

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100vh',
//         backgroundColor: '#f9f9f9',
//       }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           padding: '40px 20px',
//           maxWidth: '400px',
//           width: '100%',
//           backgroundColor: 'rgba(207, 231, 213, 0.7)',
//         }}
//       >
//         <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '20px', color: '#3B5D44' }}>
//           Login
//         </Typography>

//         <TextField
//           fullWidth
//           label="Username"
//           variant="outlined"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//           sx={{ marginBottom: '20px', backgroundColor: '#fff' }}
//         />
//         <TextField
//           fullWidth
//           label="Password"
//           type="password"
//           variant="outlined"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           sx={{ marginBottom: '20px', backgroundColor: '#fff' }}
//         />

//         {error && (
//           <Typography color="error" sx={{ textAlign: 'center', marginBottom: '20px' }}>
//             {error}
//           </Typography>
//         )}

//         <Button
//           fullWidth
//           variant="contained"
//           sx={{ backgroundColor: '#3B5D44', color: '#fff', marginBottom: '20px' }}
//           onClick={handleLogin}
//         >
//           Login
//         </Button>

//         <Typography variant="body2" sx={{ textAlign: 'center', color: '#3B5D44' }}>
//           Don't have an account?{' '}
//           <a href="/signup" style={{ color: '#EB8576', textDecoration: 'none' }}>
//             Sign up
//           </a>
//         </Typography>
//       </Paper>
//     </Box>
//   );
// }

export default Login;
