import React, { useState } from 'react';
import { Box, Button, TextField, Typography,  Grid, Link, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import GoogleIcon from "../../Assets/logos/google.png";
import MicrosoftIcon from "../../Assets/logos/microsoft.png";
import FacebookIcon from "../../Assets/logos/facebook.png";
import AppleIcon from "../../Assets/logos/apple.png";
import { useLocation } from 'wouter';
import pic from "../../Assets/signin.jpeg"

const Signup = () => {
  const [Email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [location, setLocation] = useLocation();


  const handleRegister = async () => {

    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    const payload = {
      email: Email, // Replace with your email state variable
      password: password, // Replace with your password state variable
    };
  
    try {
      const response = await axios.post("https://qnar.fun/api/register/", payload, {
        headers: {
          "Content-Type": "application/json", // Optional: Axios sets this automatically for JSON payloads
        },
      });
  
      console.log("Registration successful:", response.data);

    
      // Save user data to localStorage
      localStorage.setItem('username', response.data.username);
      localStorage.setItem('first_name', response.data.first_name); // Save first name
      localStorage.setItem('access_token', response.data.access);
      setLocation('/dashboard');
      window.location.reload();
      // Handle success (e.g., redirect to login or display success message)
    } catch (error) {
      if (error.response) {
        const serverErrors = error.response.data; // Extract server response
      if (serverErrors.email) {
        // Check if there's an email-specific error
        alert(serverErrors.email[0]); // Display the email error message
      }
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request
        console.error("Error setting up request:", error.message);
      }
    }
  };
  return (
    <Grid
      container
      sx={{
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      }}
    >
      {/* Registration Form Section */}
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          height: "80vh"
        }}
      >
        <Typography variant="h4" align="center" sx={{ mb: 3, color: "#5B945B" }}>
          Welcome to Qnar
        </Typography>
        <Grid container spacing={2}>
          {/* Email Field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          {/* Password Fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <Typography sx={{ textAlign: "center", margin: "1rem 0", color: "#5B945B" }}>
          or continue with
        </Typography>
        <Button
          variant="outlined"
          startIcon={<img src={GoogleIcon} alt="Google" style={{ width: "20px" }} />}
          sx={{ width: "100%", marginBottom: "1rem", textTransform: "none" }}
        >
          Continue with Google
        </Button>
        <Button
          variant="outlined"
          startIcon={<img src={MicrosoftIcon} alt="Microsoft" style={{ width: "20px" }} />}
          sx={{ width: "100%", marginBottom: "1rem", textTransform: "none" }}
        >
          Continue with Microsoft
        </Button>
        <Grid container justifyContent="space-around" sx={{ margin: "1rem 0" }}>
          {/* Social Media Icons */}
          {[FacebookIcon, AppleIcon].map((icon, idx) => (
            <Box
              key={idx}
              onClick={() => {
                /* Add logic here */
              }}
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <img
                src={icon}
                alt="Social Icon"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          ))}
        </Grid>
        <Button
          variant="contained"
          onClick={handleRegister}
          sx={{
            width: "100%",
            backgroundColor: "#5B945B",
            color: "white",
            marginBottom: "1rem",
            "&:hover": { backgroundColor: "#4A7A4A" },
          }}
        >
          Sign up
        </Button>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Already have an account?{" "}
          <Link href="/login" underline="hover">
            Log in
          </Link>
        </Typography>
      </Grid>

      {/* Image and Arabic Quote Section */}
      <Grid item xs={12} md={6} lg={4} sx={{ textAlign: "center" , height: "80vh", display: { xs: "none", md: "block" },}}>
        <img
          src={pic}
          alt="Online Learning"
          style={{ width: "100%", height: "70vh",borderRadius: "8px", marginBottom: "1rem",  }}
        />
        <Typography variant="h5" sx={{ color: "#5B945B", fontWeight: "bold" }}>
          من سلك طريقًا يلتمسُ فيه علمًا، سهَّل اللهُ له طريقًا إلى الجنَّةِ
        </Typography>
      </Grid>

      {/* Snackbars */}
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Registration successful!
        </Alert>
      </Snackbar>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
};



//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
//       <Typography variant="h4" gutterBottom>
//         Register
//       </Typography>

//       <Button
//         onClick={handleRegister}
//         variant="contained"
//         color="primary"
//       >
//         Register
//       </Button>


//     </Box>
//   );
// };

export default Signup;
