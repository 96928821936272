import React, { useState, useEffect } from 'react';
import { Paper, TextField,Box,FormControl, FormLabel, RadioGroup, Button, IconButton,Checkbox, Radio, Grid,FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import mainIcon from '../../Assets/ClickPick.png'
import { useTranslation } from 'react-i18next';
import { useLocation,useRoute  } from 'wouter';

function ClickPickCreate () {
    
    const [showPopup, setShowPopup] = useState(false);

    const [questions, setQuestions] = useState([{ title: '', answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false }]}]);
    const [location, setLocation] = useLocation();
    const [match, params] = useRoute('/ClickPickCreate/:id'); // Use the same route pattern
    const { id } = params; // Get the id from params
    const [username, setUsername] = useState('');
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);
    const [deleteAdd, setDeleteAdd]= useState(0)
    const [GameTitle, setGameTitle]= useState('')
    const [answerType, setAnswerType] = useState('word');
    const [GameDescription, setDescription] = useState('');
    const {t, i18n} = useTranslation();

    useEffect(() => {
        // Example of getting auth status from localStorage (or any other method)
        const token = localStorage.getItem('access_token');
        const storedUsername = localStorage.getItem('username');

        if (!token || !storedUsername) {
            setLocation('/'); // Redirect to the homepage or login
        } else {
            setUsername(storedUsername || '');
            if (id != 1 ) {
              console.log(id);
              fetchGameData(id);
          }
        }

    }, []);
    const fetchGameData = async (gameId) => {
      try {
          const response = await fetch(`https://qnar.fun/api/games/${gameId}`);
          if (!response.ok) throw new Error('Failed to fetch game data');
          const gameData = await response.json();
  
          // Populate states
          setGameTitle(gameData.title);
          setDescription(gameData.description);
  
          // Fetch questions associated with the game
          const questionsResponse = await fetch(`https://qnar.fun/api/questions?game=${gameId}`);
          if (!questionsResponse.ok) throw new Error('Failed to fetch questions');
          const questionsData = await questionsResponse.json();
          console.log(questionsData);
          setQuestions(
              questionsData.map((q) => ({
                  id: q.id,
                  title: q.title,
                  answers: q.answers.map((a) => ({
                      text: a.text,
                      isCorrect: a.isCorrect || false,
                      image: a.image || null,
                  })),
              }))
          );
      } catch (error) {
          console.error('Error fetching game data:', error);
      }
  };
    const addQuestion = () => {
        setQuestions([...questions, { title: '', answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false}]}]);
    };

    const addAnswer = (qIndex) => {
        const newQuestions = [...questions];
        if (newQuestions[qIndex].answers.length < 6) {
            newQuestions[qIndex].answers.push({ text: '', image: null });
            setQuestions(newQuestions);
        } else {
            setDeleteAdd(1);
            setDeleteWarningOpen(true);
        }
        
    };

    const handleQuestionChange = (index, value) => {
        const newQuestions = [...questions];
        newQuestions[index].title = value;
        setQuestions(newQuestions);
    };

    const handleAnswerChange = (qIndex, aIndex, value) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].answers[aIndex].text = value;
        setQuestions(newQuestions);
    };


    const deleteAnswer = (qIndex, aIndex) => {
        const newQuestions = [...questions];
        if (newQuestions[qIndex].answers.length > 2) {
            newQuestions[qIndex].answers.splice(aIndex, 1);
            if (newQuestions[qIndex].correctAnswerIndex === aIndex) {
                newQuestions[qIndex].correctAnswerIndex = null; // Reset correct answer if it was deleted
            }
            setQuestions(newQuestions);
        } else {
            setDeleteAdd(0);
            setDeleteWarningOpen(true);
        }
    };

    const handleImageUpload = (qIndex, aIndex, event) => {
        const newQuestions = [...questions];
        newQuestions[qIndex].answers[aIndex].image = URL.createObjectURL(event.target.files[0]);
        setQuestions(newQuestions);
    };
    const handleTitleChange = (e) => {
        const newValue = e.target.value;
        setGameTitle(newValue);
        // Add your additional function here

    };
    
    const handleCloseDeleteWarning = () => {
        setDeleteWarningOpen(false);
    };
    const postGame = async () => {
        try {
            const gameData = {
                gameType: 2,
                title: GameTitle,
                description: GameDescription,
                creator: username, // Make sure username is a string here
            };
    
          const response = await fetch('https://qnar.fun/api/games/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(gameData),
          });
          const data = await response.json();
          return data.id; // Return the game ID for creating questions
        } catch (error) {
          console.error('Error posting game:', error);
        }
      };
    
    
      const postQuestion = async (questionData, gameId) => {
        try {
          const response = await fetch('https://qnar.fun/api/questions/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...questionData, game: gameId }),
          });
    
        } catch (error) {
          console.error('Error posting question:', error);
        }
      };
    
const updateGame = async () => {
  try {
      const gameData = {
          title: GameTitle,
          description: GameDescription,
      };
      const response = await fetch(`https://qnar.fun/api/games/${id}/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(gameData),
      });
      if (!response.ok) {
        const errorText = await response.text();  // Handle non-JSON response
        throw new Error(errorText);
      }
  
      const data = await response.json();
      return data.id;
    } catch (error) {
      console.error('Error updating game:', error);
    }
  };

const updateQuestion = async (questionData) => {
  try {
    const response = await fetch(`https://qnar.fun/api/questions/${questionData.id}/`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(questionData),
      });
      if (!response.ok) {
        const errorText = await response.text();  // Handle non-JSON response
        throw new Error(errorText);
      }
  
  } catch (error) {
      console.error('Error updating question:', error);
  }
};
    const handleNext = async() => {
        setShowPopup(true);
        if (id != 1) { // Check if editing
          const gameId = await updateGame(); // Update existing game
          for (const question of questions) {
              await updateQuestion({id:question.id, title: question.title, answers: question.answers, game: gameId });
          }
      } else {
        const gameId = await postGame();
        for (const question of questions) {
            await postQuestion({ title: question.title, answers: question.answers }, gameId);
        }
    }
    setLocation(`/play/ClickPick/${id}`);
        


    };


    const handleCorrectAnswerChange = (qIndex, aIndex, checked) => {
        const updatedQuestions = [...questions];
        updatedQuestions[qIndex].answers[aIndex].isCorrect = checked;
        setQuestions(updatedQuestions); // Assuming you're using state management like useState for questions
      };
    return (
        <Box 
  p={3} 
  maxWidth="100%" 
  mx="auto" 
  minHeight="100vh" /* Ensures the content occupies the full viewport height */
  display="flex" 
  flexDirection="column" 
  justifyContent="center" 
  alignItems="center" 
  backgroundColor="#F5FFF8"
  color={"#3B5D44"}
>
            {/* Game Icon and Description */}
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={mainIcon} alt="Game Icon" style={{ width: '20vw', height: 'auto', marginTop:'20px' }} />
                <Typography  variant="h3" style={{ fontWeight:'bold'}}gutterBottom>{t('ClickPickTitle')}</Typography>
                <Typography variant="h6">{t('ClickPickH2')}</Typography>
            </div>

            {/* Add Content Section */}
            <Paper style={{width:'90%', padding: '20px', marginBottom: '0px', marginLeft: 20, marginRight:20 ,color :'#4B7857', fontWeight:'bold'}}>
              <Typography variant= "h2"style={{ textAlign: 'center',fontWeight:'bold' }} >{t('Content')}</Typography>
            
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '-8px' }}>
  {t('CTitle')} {/* Title text */}
</Typography>
                <TextField label={t('CAddTitle')} fullWidth margin="normal" value={GameTitle}
                onChange={handleTitleChange}/>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '-8px' }}>
  {t('CDescription')} {/* Title text */}
</Typography>
                <TextField label={t('CAddDescription')} fullWidth margin="normal"  value={GameDescription}
                onChange={(e) => setDescription(e.target.value)}/>
<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '-8px' }}>
  {t('CQuestion')} {/* Title text */}
</Typography>
{questions.map((question, qIndex) => (
  <div key={question.id} style={{ marginBottom: '20px' }}>
    <Grid container spacing={2}>
      {/* Question Label (e.g., Q1) */}
      <Grid item xs={"auto"}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Q{qIndex + 1}
        </Typography>
      </Grid>

      {/* Question TextField */}
      <Grid item xs={11}>
        <TextField
          fullWidth
          label={t('CAddQuestion')}
          value={question.title}
          onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
        />
      </Grid>
    </Grid>

    {/* Answers for the Question */}
    <Grid container spacing={2} style={{ marginLeft: '40px', marginTop: '10px' }}>
      {question.answers.map((answer, aIndex) => (
        <Grid container key={aIndex} alignItems="center" spacing={2}>
          
          {/* Answer Label (A, B, C, etc.) */}
          <Grid item xs={"auto"}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {String.fromCharCode(65 + aIndex)}.
            </Typography>
          </Grid>

          {/* Word Answer TextField */}
          <Grid item xs={9}>
            <TextField
              fullWidth
              margin="normal"
              label={t('CAddAnswer')}
              value={answer.text}
              onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}
            />
          </Grid>

          {/* Correct Answer Checkbox */}
          <Grid item xs={"auto"}>
            <Checkbox
              checked={answer.isCorrect || false}
              onChange={(e) => handleCorrectAnswerChange(qIndex, aIndex, e.target.checked)}
              color="primary"
            />
            <Typography variant="body2">Correct</Typography>
          </Grid>

          {/* Delete Answer Button */}
          <Grid item xs={"auto"}>
            <IconButton onClick={() => deleteAnswer(qIndex, aIndex)} color="secondary" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        startIcon={<AddCircleIcon />}
        onClick={() => addAnswer(qIndex)}
        style={{ marginTop: '10px', marginBottom: '20px' }}>
        {t('addA')}
      </Button>
    </Grid>
  </div>
))}


   

                <Button startIcon={<AddCircleIcon />} onClick={addQuestion}>
                {t('addQ')}
                </Button>
                   {/* Next Button */}
            <div style={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" onClick={handleNext}>
                {t('Next')}
                </Button>
            </div>
            </Paper>

         

            {/* Delete Warning Dialog */}
            <Dialog
                open={deleteWarningOpen}
                onClose={handleCloseDeleteWarning}
            >
                <DialogTitle>{t('warning')}</DialogTitle>
                <DialogContent>
                {deleteAdd === 0?
                    <DialogContentText>
                        {t('least2')}
                        
                    </DialogContentText>:
                    <DialogContentText>
                        {t('max4')}
                   
                </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteWarning} color="primary">
                    {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle>{t('Game Created')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Your game has been successfully created!')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* Close Button */}
          <Button onClick={() => setShowPopup(false)} color="secondary">
            {t('Close')}
          </Button>

          {/* Go to Game Button */}
         
        </DialogActions>
      </Dialog>
        </Box>
    );
};

export default ClickPickCreate;
